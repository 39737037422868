<template>
  <div>
    <h4 class="pl-2 font-weight-bold">ギフト券管理</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  @input="fromDateSelected"
                  button-only
                  locale="ja"
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="toDate"
                  @input="toDateSelected"
                  button-only
                  locale="ja"
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
              <b-button
                class="primary mb-4 mb-md-3 ml-2"
                block
                @click="infoModal()"
                >新規作成 <i class="fa fa-plus" aria-hidden="true"></i
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="col.key !== 'id'"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(name)="row">
        {{ row.item.name }}
      </template>
      <template #cell(numberPoints)="row">
        <b-badge variant="warning" class="py-2">
          {{ formatPoint(row.item.numberPoints) }}</b-badge
        >
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="infoModal(row.item)"
          ><b-badge variant="primary" class="py-2">更新</b-badge
          ><esports-loading-button
            v-if="row.item.cardId === cardInfo.cardId && action_type === 'edit'"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></span>
        <span
          role="button"
          class="mx-2 my-1 d-inline-block"
          @click="deleteModal(row.item)"
          ><b-badge variant="danger" class="py-2">削除</b-badge></span
        >
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row)"
          ><b-badge variant="info" class="text-white py-2">{{ row.detailsShowing ? "詳細非表示" : "詳細表示" }}</b-badge
          ><esports-loading-button
            v-if="row.item.cardId === cardInfo.cardId && action_type === 'info'"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
      </template>

      <template #row-details="row">
        <b-card no-body class="overflow-hidden animate__animated" :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']">
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <b-card-img-lazy
                v-if="row.item.content"
                :src="row.item.content.picture"
                alt="Image"
                class="rounded-0"
                v-bind="mainProps('#fff')"
              ></b-card-img-lazy>
              <label class="mt-1 ml-1" v-if="row.item.content">{{ row.item.content.labelFileName }}</label>
            </b-col>
            <b-col md="7" lg="7">
              <b-card-body>
                <h5>{{ row.item.title }}</h5>
                <b-card-text>
                  <b>ドネト: </b>
                  <b-badge variant="warning" class="py-2">
                    {{ formatPoint(row.item.numberPoints) }}</b-badge
                  >
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Card ID</b>: {{ row.item.cardId }}</li> -->
                <li><b>作成日</b>: {{ row.item.createdDate }}</li>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging 
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult">
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0 ml-3"
        ></b-pagination>
        </template>
      </template>
    </esports-paging>

    <!-- create/update Game Modal -->
    <b-modal
      :id="cardInfo.id"
      :title="cardInfo.title_modal"
      @hide="resetcardInfo"
      @shown="handleUpdateCard"
      ref="card-info-modal"
    >
      <div class="form-modal">
        <transition-alert>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
        </transition-alert>
        <b-form-group class="mb-2">
          <label>タイトル</label>
          <b-form-input
            v-model="cardInfo.title"
            type="text"
            :state="ckStateGame('title')"
          ></b-form-input>
          <b-form-invalid-feedback
            >タイトルは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mb-2">
          <label>ドネト</label>
          <b-form-input
            v-model.number="cardInfo.numberPoints"
            type="number"
            min="0"
            :state="ckStateGame('numberPoints')"
          ></b-form-input>
          <b-form-invalid-feedback
            >ドネトは必須です。</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group class="mt-3 position-relative">
          <b-form-file
            v-model="cardInfo.file"
            placeholder=""
            :state="cardId ? '' : ckStateGame('file')"
            drop-placeholder=""
            class="hide-banner"
            accept="image/*"
          ></b-form-file>
          <label class="custom-file-label filename">{{ filename }}</label>
          <b-form-invalid-feedback>画像アップロードは必須です。</b-form-invalid-feedback>
        </b-form-group>
      </div>
      <template #modal-footer="{ close }">
        <b-button
          class="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="onSubmitEvent(close)"
          >{{ cardId ? "更新" : "登録" }}
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
    <!-- delete Game Modal -->
    <b-modal
      id="deleteEvent"
      hide-header
      header-class="text-danger"
      centered
    >
      <div class="form-data">
        <h5 class="text-center py-3">
          このギフト券を完全に削除してもよろしいでしょうか？
        </h5>
        <transition-alert>
          <template v-if="msgSuccess">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="success"
            >
              {{ msgSuccess }}
            </b-alert>
          </template>
          <template v-if="msgErrors.length">
            <b-alert
              :show="dismissCountDown"
              dismissible
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChange"
              variant="danger"
            >
              <span
                class="d-block"
                v-for="(msg, index) in msgErrors"
                :key="index"
                >{{ msg }}</span
              >
            </b-alert>
          </template>
        </transition-alert>
      </div>
      <template #modal-footer="{ close, cancel }">
        <b-button @click="cancel">キャンセル</b-button>
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitted"
          @click="handleDeleteCard(close)"
          >OK
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';

const greaterThanZero = (value) => value >= 0;

export default {
  name: "Cards",
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      cardId: "",
      items: [],
      fields: [
        {
          key: "title",
          label: "タイトル",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "numberPoints",
          label: "ドネト",
          sortable: true,
        },
        {
          key: "createdDate",
          label: "作成日",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        title: "",
        numberPoints: "",
        createdDate: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["title", "numberPoints", "createdDate"],
      cardInfo: {
        id: "info-modal",
        file: null,
        title_modal: "",
        title: "",
        numberPoints: "",
      },
      cardTemp: null,
      msgErrors: [],
      msgSuccess: null,
      action_type: "",
      isEnableCloseModal: false,
      filename: "",
      limit: 0,
      isDetailRow: false
    };
  },
  mixins: [validationMixin, dismissCount],
  validations() {
    if (this.cardId) {
      return {
        cardInfo: {
          title: { required },
          numberPoints: { required, maxValue: greaterThanZero },
        },
      };
    }
    return {
      cardInfo: {
        title: { required },
        numberPoints: { required, maxValue: greaterThanZero },
        file: { required },
      },
    };
  },
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    msgSuccess(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
    "cardInfo.file": {
      deep: true,
      immediate: true,
      handler(nVal) {
        if (nVal) {
          this.filename = nVal.name;
        } else {
          this.filename = "画像をアップロード";
        }
      },
    },
  },
  computed: {
    isValidCard() {
      return !this.$v.cardInfo.$anyError;
    },
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });

      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([this.getCardsAll()]);
    this.hideBeforeLoading = true;

    this.cardTemp = { ...this.cardInfo };

    if (this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    handleUpdateCard() {
      this.cardTemp = { ...this.cardInfo };
    },
    showModalConfirmCloseCard() {
      this.$bvModal
        .msgBoxConfirm("本当にキャンセルしてもよろしいでしょうか？", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2 footer-mgs",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isEnableCloseModal = true;
            this.$refs["card-info-modal"].hide();
          }
        });
    },
    async getCardsAll(params) {
      const result = await this.$store
        .dispatch("adminCard/getCardsAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((player, index) => {
          player.picture = `${process.env.VUE_APP_BACKEND + player.picture}`;

          if (player.createdDate) {
            player.createdDate = moment(player.createdDate).format(
              "YYYY-MM-DD"
            );
          }

          return player;
        });

        this.items = orderBy(this.items, ['createdDate'], ['desc']);

        this.totalRows = this.items.length;
        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getCardsAll(params);
    },
    async onSubmitEvent(close) {
      if (this.validate()) {
        let params = {
          file: this.cardInfo.file,
          cardInfo: "",
          cardId: this.cardId,
        };

        let cardInfo = {
          title: this.cardInfo.title,
          numberPoints: this.cardInfo.numberPoints,
        };

        if (this.cardId) {
          cardInfo.cardId = this.cardId;
        }

        params.cardInfo = JSON.stringify(cardInfo);

        this.showMsgConfirmCreate(() => {
          this.isEnableCloseModal = true;
          this.upSertCard(params, close);
        });
      }
    },
    async upSertCard(params, close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminCard/upSertCard", params)
        .catch((err) => {
          this.isSubmitted = false;

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListCard(result, close);
      }
    },
    showMsgConfirmCreate(cb) {
      let msg = this.cardId
        ? "このギフト券を更新してもよろしいですか？"
        : "このゲームを登録してもよろしいですか？";

      this.$bvModal
        .msgBoxConfirm(msg, {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.isEnableCloseModal = true;
          this.$refs["card-info-modal"].hide();
        });
    },
    async infoModal(item) {
      if (!item) {
        this.cardId = "";
        this.cardInfo.title_modal = `新規作成`;
        this.cardInfo.id = "info-modal";
        this.cardInfo.cardId = "";

        this.cardTemp.title_modal = `新規作成`;
        this.cardTemp.id = "info-modal";
        this.cardTemp.cardId = "";

        this.filename = "画像をアップロード";

        this.$root.$emit("bv::show::modal", this.cardInfo.id);
        return;
      }
      this.cardInfo.title_modal = `更新`;
      this.cardInfo.cardId = item.cardId;
      this.action_type = "edit";

      const result = await this.$store
        .dispatch("adminCard/getDetailCard", item.cardId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.loadDataInfo(item, result);

        this.$root.$emit("bv::show::modal", this.cardInfo.id);
      }
    },
    async handleDeleteCard(close) {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("adminCard/deleteCard", this.cardId)
        .catch((err) => {
          console.log(err);

          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      if (result) {
        this.reloadListCard(result, close);
      }
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.title = "";
      this.filters.numberPoints = "";
      this.filters.createdDate = "";

      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    async reloadListCard(result, close) {
      this.msgSuccess = result.message;
      this.msgErrors = [];
      this.isSubmitted = false;
      this.resetData();
      this.handleSuccessResp(close);
      await this.handleFilterEvents();
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    toDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    validate() {
      this.$v.$touch();
      return this.isValidCard;
    },
    ckStateGame(val) {
      let field = this.$v.cardInfo[val];
      return !field.$dirty || !field.$invalid;
    },
    resetData() {
      this.page = 1;
      this.cardId = "";
      this.fromDate = "";
      this.toDate = "";
      this.perPage = 20;
    },
    deleteModal(item) {
      this.cardId = item.cardId;
      this.$root.$emit("bv::show::modal", "deleteEvent");
    },
    resetcardInfo(data) {
      if (
        !isEqual(this.cardInfo, this.cardTemp) &&
        !this.isEnableCloseModal
      ) {
        this.showModalConfirmCloseCard();
        data.preventDefault();
        return;
      }

      this.cardInfo.title_modal = "";
      this.cardInfo.file = null;
      this.cardInfo.title = "";
      this.cardInfo.numberPoints = "";
      this.action_type = "";
      this.msgErrors = [];

      this.isEnableCloseModal = false;
      this.filename = "";

      this.$v.$reset();
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    async handleDetail(row) {
      let currentIdx = this.items.findIndex(
        (team) => team.cardId === row.item.cardId
      );
      this.cardInfo.cardId = row.item.cardId;
      this.action_type = "info";
      this.isDetailRow = true;

      if (row.detailsShowing) {
        this.cardInfo.cardId = "";
        this.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      const result = await this.$store
        .dispatch("adminCard/getDetailCard", row.item.cardId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        this.cardInfo.cardId = "";
        let content = result;
        content.picture = `${process.env.VUE_APP_BACKEND + content.picture}`;

        content.labelFileName = this.getNamePicture(result.picture);

        if (currentIdx > -1) {
          this.items[currentIdx].content = result;
        }
        row.toggleDetails();
      }
    },
    handleSuccessResp(close) {
      setTimeout(() => {
        close();
        this.msgSuccess = null;
        this.dismissCountDown = 0;
      }, 2000);
    },
    loadDataInfo(item, result) {
      this.cardInfo.cardId = "";
      this.cardInfo.title = result.title;
      this.cardId = item.cardId;
      this.cardInfo.numberPoints = result.numberPoints;

      this.filename = this.getNamePicture(result.picture);
    },
  },
};
</script>